/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class DebugService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get Token
     * Generates a new JWT token for the given user eppn for the given duration in seconds
     * @param eppn 
     * @param duration 
     * @returns any Successful Response
     * @throws ApiError
     */
    public getToken(
eppn: string,
duration: number = 3600,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/debug/generate_token',
            query: {
                'eppn': eppn,
                'duration': duration,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
