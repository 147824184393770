/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PublicLicenseID {
    CC0_1_0 = 'cc0-1.0',
    CC_BY_4_0 = 'cc-by-4.0',
    CC_BY_SA_4_0 = 'cc-by-sa-4.0',
    CC_BY_NC_4_0 = 'cc-by-nc-4.0',
    CC_BY_NC_SA_4_0 = 'cc-by-nc-sa-4.0',
    CC_BY_ND_4_0 = 'cc-by-nd-4.0',
    CC_BY_NC_ND_4_0 = 'cc-by-nc-nd-4.0',
}
